import React from "react";

export const Contact = (props) => {
	return (
		<div>
			<div id="contact">
				<div className="container">
					<div className="col-md-8">
						<div className="row">
							<div className="section-title">
								<h2>Get In Touch</h2>
								<p>
									Feel free to reach out to us through phone, email or WhatsApp.
								</p>
							</div>
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d432906.0603743683!2d115.96153564999999!3d-32.04006395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a32bb64a1426ab3%3A0x3d29667c3f0d7b1f!2sH%20P%20Professional!5e0!3m2!1sen!2sbt!4v1718959205149!5m2!1sen!2sbt"
								id="iframe"
								height="200"
								title="Google Maps Embed for HP Professional"
								style={{ border: 0 }}
								allowFullScreen=""
								loading="lazy"
								referrerPolicy="no-referrer-when-downgrade"
							></iframe>
						</div>
					</div>
					<div className="col-md-3 col-md-offset-1 contact-info">
						<div className="contact-item">
							<h3>Contact Info</h3>
							<p>
								<span>
									<i className="fa fa-map-marker"></i> Address
								</span>
								{props.data ? props.data.address : "loading"}
							</p>
						</div>
						<div className="contact-item">
							<p>
								<span>
									<i className="fa fa-phone"></i> Phone
								</span>{" "}
								{props.data ? props.data.phone : "loading"}
							</p>
						</div>
						<div className="contact-item">
							<p>
								<span>
									<i className="fa fa-envelope-o"></i> Email
								</span>{" "}
								{props.data ? props.data.email : "loading"}
							</p>
						</div>
					</div>
					<div className="col-md-12">
						<div className="row">
							<div className="social">
								<ul>
									<li>
										<a href={props.data ? props.data.whatsapp : "/"}>
											<i class="fa fa-whatsapp" aria-hidden="true"></i>
										</a>
									</li>
									<li>
										<a href={props.data ? props.data.youtube : "/"}>
											<i className="fa fa-youtube-play"></i>
										</a>
									</li>
									<li>
										<a href={props.data ? props.data.mailto : "/"}>
											<i className="fa fa-envelope-o"></i>
										</a>
									</li>
									<li>
										<a href={props.data ? props.data.maps : "/"}>
											<i className="fa fa-map-marker"></i>
										</a>
									</li>
									<li>
										<a href={props.data ? props.data.truelocal : "/"}>
											<i className="fa fa-star"></i>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="footer">
				<div className="container text-center">
					<p>
						&copy; 2024 HP Professional. Designed and Developed by{" "}
						<a href="https://www.linkedin.com/in/divyashc/" rel="nofollow">
							divyashC
						</a>
					</p>
				</div>
			</div>
		</div>
	);
};
