import React from "react";
import { Navigation } from "./navigation";
import { Contact } from "./contact";
import JsonData from "../data/data.json";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export const MoreServices = (props) => {
	const [landingPageData, setLandingPageData] = useState({});
	useEffect(() => {
		setLandingPageData(JsonData);
	}, []);

	// scroll to the top of the page
	window.scrollTo(0, 0);

	return (
		<>
			<Navigation />
			<div id="more-services" className="text-center">
				<div className="container">
					<div className="section-title">
						<h2>Our Services</h2>
						<p>
							We deliver comprehensive, high-quality solutions for a pristine
							and hygienic environment.
						</p>
					</div>
					<div className="list">
						{props.data
							? props.data.map((d, i) => (
									<div key={`${d.name}-${i}`} className="col-md-3 col-sm-auto">
										<Link to={`/service/${d.slug}`}>
											<i className={d.icon}></i>
											<div className="service-desc">
												<h3>{d.name}</h3>
												<p>{d.text}</p>
											</div>
										</Link>
									</div>
							  ))
							: "loading"}
					</div>
				</div>
				<a
					href="https://wa.me/+61411568779"
					className="btn btn-custom btn-lg page-scroll"
					target="_blank"
				>
					Get free quote
				</a>{" "}
			</div>
			<Contact data={landingPageData.Contact} />
		</>
	);
};
