import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { FAQ } from "./components/faq";
import { Contact } from "./components/contact";
import { MoreServices } from "./components/moreServices";
import JsonData from "./data/data.json";
import ServiceDetail from "./components/ServiceDetail";
import SmoothScroll from "smooth-scroll";
import { HelmetProvider } from "react-helmet-async";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
	speed: 1000,
	speedAsDuration: true,
});

const App = () => {
	const [landingPageData, setLandingPageData] = useState({});
	useEffect(() => {
		setLandingPageData(JsonData);
	}, []);

	const helmetContext = {};

	return (
		<HelmetProvider context={helmetContext}>
			<Router>
				<Navigation />
				<Routes>
					<Route
						path="/"
						element={
							<>
								<Header data={landingPageData.Header} />
								<About data={landingPageData.About} />
								<Services data={landingPageData.Services} />
								<Features data={landingPageData.Features} />
								<FAQ data={landingPageData.Faq} />
								<Gallery data={landingPageData.Gallery} />
								<Contact data={landingPageData.Contact} />
							</>
						}
					/>
					{/* add one route called /more-services that opens MoreServices */}
					<Route
						path="/more-services"
						element={<MoreServices data={landingPageData.Services} />}
					/>
					{landingPageData.Services?.map((service, index) => (
						<Route
							key={index}
							path={`/service/${service.slug}`} // Use unique slugs for paths
							element={<ServiceDetail service={service} />}
						/>
					))}
				</Routes>
			</Router>
		</HelmetProvider>
	);
};

export default App;
