import React, { useState } from "react";

export const FAQ = (props) => {
	const [activeIndex, setActiveIndex] = useState(null);

	const toggleFAQ = (index) => {
		setActiveIndex(activeIndex === index ? null : index);
	};

	return (
		<div id="faqs">
			<div className="container">
				<div className="section-title text-center">
					<h2>Frequently Asked Questions</h2>
				</div>
				<div className="faq-list">
					{props.data
						? props.data.map((d, i) => (
								<div
									key={`${d.name}-${i}`}
									className={`faq ${activeIndex === i ? "active" : ""}`}
								>
									<div className="faq-title" onClick={() => toggleFAQ(i)}>
										{d.name}
										<i
											className={`fa ${
												activeIndex === i ? "fa-angle-up" : "fa-angle-down"
											}`}
										></i>
									</div>
									<div
										className="faq-content"
										style={{ display: activeIndex === i ? "block" : "none" }}
									>
										<p>{d.text}</p>
									</div>
								</div>
						  ))
						: "Loading..."}
				</div>
			</div>
		</div>
	);
};
