import React from "react";
import { Navigation } from "./navigation";
import { Contact } from "./contact";
import JsonData from "../data/data.json";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";

const ServiceDetail = ({ service }) => {
	const [landingPageData, setLandingPageData] = useState({});
	useEffect(() => {
		setLandingPageData(JsonData);
	}, []);

	// scroll to the top of the page
	window.scrollTo(0, 0);

	if (!service) return <p>Loading...</p>;

	return (
		<>
			<Helmet>
				<title>{service.name}</title>
				<meta name="keywords" content={service.meta} />
			</Helmet>
			<Navigation />
			<div className="service-detail">
				<h2>{service.name}</h2>
				<p>{service.description}</p>
			</div>
			<Contact data={landingPageData.Contact} />
		</>
	);
};

export default ServiceDetail;
