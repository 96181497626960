import React from "react";
import { Link } from "react-router-dom";

export const Services = (props) => {
	return (
		<div id="services" className="text-center">
			<div className="container">
				<div className="section-title">
					<h2>Our Services</h2>
					<p>
						We deliver comprehensive, high-quality solutions for a pristine and
						hygienic environment.
					</p>
				</div>
				<div className="row">
					{props.data
						? props.data.slice(0, 8).map((d, i) => (
								<div key={`${d.name}-${i}`} className="col-md-3 col-sm-auto">
									<Link to={`/service/${d.slug}`}>
										<i className={d.icon}></i>
										<div className="service-desc">
											<h3>{d.name}</h3>
											<p>{d.text}</p>
										</div>
									</Link>
								</div>
						  ))
						: "loading"}
				</div>
				<a href="/more-services" className="btn btn-custom btn-lg page-scroll">
					View More
				</a>{" "}
			</div>
		</div>
	);
};
